export default {
  props: {
    faqs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      openIndex: 0
    }
  },
  methods: {
    enter(element) {
      element.style.width = getComputedStyle(element).width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.height = height
      })
    },
    leave(element) {
      element.style.height = getComputedStyle(element).height

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height

      setTimeout(() => {
        element.style.height = 0
      })
    },
    openElement(index) {
      this.openIndex = index
    },
    isVisible(index) {
      return index === this.openIndex
    }
  }
}
